import React, { Suspense, lazy } from 'react';
import './Stylesheets/App.css';
import Navbar from './Components/Navbar';
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import Preloader from './Components/Preloader';

const Home = lazy(() => import('./Components/Home'));
const Signup = lazy(() => import('./Components/Signup'));
const Login = lazy(() => import('./Components/Login'));
const Reset = lazy(() => import('./Components/Reset'));
const Video = lazy(() => import('./Components/Video'));
const Route404 = lazy(() => import('./Components/route404'));

function App() {
  return (
    <>
    <Router>
    <Navbar title='Cybernet13'/>
    <Suspense fallback={<Preloader/>}>
        <Switch>
          <Route exact path="/">
              <Home />
            </Route>
          <Route exact path="/signup">
              <Signup />
            </Route>
          <Route exact path="/login">
              <Login />
            </Route>
          <Route exact path="/reset">
              <Reset />
            </Route>
          <Route exact path="/video">
              <Video />
            </Route>
          <Route>
            <Route404/>
          </Route>
        </Switch>
        </Suspense>
    </Router>
    </>
  );
}

export default App;
