import React from 'react';
import { Link } from 'react-router-dom';
import  logo  from '../Images/logo.svg';

export default function Navbar(props) {
    return (
        <>
            <div className="nav" >
                <Link to='/'><img src={logo} width='180' height="180" alt=''/><span className="logo">Logo</span></Link>
                <div className='options'>
                    <div className="link">
                        <Link to="/">Home</Link>
                        <Link to="/signup">Signup</Link>
                        <Link to="/login">Login</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
Navbar.defaultProps={
    title:'Cybertech13',
}